import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-button',
  standalone: true,
  imports: [],
  template: '<button class="btn btn-primary btn-block" (click)="auth.loginWithRedirect()">Log in</button>',
  styleUrl: './auth-button.component.scss'
})
export class AuthButtonComponent {
  // Inject the authentication service into your component through the constructor
  constructor(public auth: AuthService) {}
}

