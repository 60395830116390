<div class="grid-container">
  @if (isSuperAdmin) {
    <h2 class="mat-h2 ps-3">Manage All Clubs</h2>
    <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">
      <!--Mini Cards-->
      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">Total Clubs</h5>
          <p class="display-1 mx-auto">22</p>
        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">All Reg Club Members</h5>
          <p class="display-1 mx-auto">941</p>
        </app-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">NCA Club Members</h5>
          <p class="display-1 mx-auto">484</p>
        </app-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">% NCA Members</h5>
          <p class="display-1 mx-auto">51%</p>
        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols"
                     [rowspan]="( cardLayout | async )?.table?.rows">
        <app-card>
          <h4 class="mx-auto">Clubs out of Compliance</h4>
          <table class="text-start table-hover w-75">
            <tr>
              <th>Club Name</th>
              <th>Club Abbr</th>
              <th>Reason</th>
            </tr>
            <tr>
              <td>Newfoundland Club of New England</td>
              <td>NCNE</td>
              <td>Too Slow</td>
            </tr>
            <tr>
              <td>High Country Newfoundland Club</td>
              <td>HCNC</td>
              <td>Too High</td>
            </tr>
            <tr>
              <td>North Central Newfoundland Club</td>
              <td>NCNC</td>
              <td>Documents Overdue</td>
            </tr>
          </table>
        </app-card>
      </mat-grid-tile>
    </mat-grid-list>
  } @else {
    <div class="text-center">
      <h2 class="mat-h2 ps-3">Manage {{ regionalClubName }}</h2>
      <img src="{{ logoFileName }}" alt="Logo Image">
    </div>
    <mat-grid-list cols="{{ ( cardLayout | async )?.columns }}" rowHeight="200px">
      <!--Mini Cards-->
      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">Reported {{ regionalClubAbbr }} Members</h5>
          <p class="display-1 mx-auto">122</p>
        </app-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">% NCA Members</h5>
          <p class="display-1 mx-auto">68%</p>
        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">Yet Another</h5>
          <p class="display-1 mx-auto">6</p>
        </app-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="( cardLayout | async )?.miniCard?.cols"
                     [rowspan]="( cardLayout | async )?.miniCard?.rows">
        <app-card>
          <h5 class="mx-auto">Something else</h5>
          <p class="display-1 mx-auto">11</p>
        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols"
                     [rowspan]="( cardLayout | async )?.table?.rows">
        <app-card>
          <h4 class="mx-auto">{{ regionalClubName }} Details</h4>
          <table class="text-start table-hover table-bordered w-100">
            <tbody>
              <tr>
                <td>Club is currently "in compliance"</td>
                <td>True</td>
                <td>No Action required</td>
              </tr>
              <tr>
                <td>Last Submitted Club Report Date</td>
                <td>12/31/2023</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </app-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="( cardLayout | async )?.table?.cols"
                     [rowspan]="( cardLayout | async )?.table?.rows">
        <app-card>
          <h4 class="mx-auto">Action/Alerts for the {{ regionalClubName }}</h4>
          <table class="text-start table-hover w-100">
            <tbody>
              <tr class="border-bottom border-top p-2">
                <td class="p-2">Pending Boat Registration</td>
                <td class="text-center p-2"><button class="btn btn-secondary">Check Status</button> </td>
              </tr>
              <tr class="border-bottom p-2">
                <td class="p-2">Working Dog test application approved</td>
                <td class="text-center p-2"><button class="btn btn-secondary">Finish</button> </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </app-card>
      </mat-grid-tile>
    </mat-grid-list>
  }

</div>
