import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { RegionalclubService } from '../../services/regionalclub.service';
import { RegionalClub } from '../../models/basics.model';
import { Subject, takeUntil } from 'rxjs';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem,
  NgbModal,
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLinkButton,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { NgStyle } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { TokenStorageService } from '../../services/token-storage.service';
import { EditModalComponent } from '../edit-modal/edit-modal.component';
import { FileHolder, ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-clubs',
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavContent,
    NgbNavLinkButton,
    NgbNavOutlet,
    NgStyle,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgbAccordionDirective,
    MatSlideToggle,
    FormsModule,
    ImageUploadComponent
  ],
  templateUrl: './clubs.component.html',
  styleUrl: './clubs.component.scss'
})
export class ClubsComponent {
  active = 'top';
  allClubs: RegionalClub[] = [];
  workingClubs: RegionalClub[] = [];
  private unsubscribe$ = new Subject<void>();
  isCompliant: boolean = true;
  isSuperAdmin = false;
  protected regionalClubName = 'Unknown Club Name';
  protected regionalClubAbbr = 'Unknown Club Abbr';
  myClub: RegionalClub = {
    abbreviation: '',
    areaServed: '',
    contactEmail: '',
    contactName: '',
    facebookId: '',
    facebookUrl: '',
    id: 0,
    instagramUrl: '',
    isActive: 0,
    logoFileName: '',
    name: '',
    rescueUrl: '',
    twitterUrl: '',
    websiteUrl: '',
    zipCodes: ''
  }
  private photoFilePath: any;
  private customErrorMessage = '';
  private uploadFailed = false;

  constructor(private router: Router,
              private regService: RegionalclubService,
              private tokenService: TokenStorageService,
              private modalService: NgbModal,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    const roles = this.tokenService.getRoles();
    if (roles && roles.length > 0) {
      if (roles[0].role === 'superAdmin') {
        this.isSuperAdmin = true;
      } else {
        this.regionalClubAbbr = roles[0].abbr;
        this.regionalClubName = roles[0].name;
        this.loadMyClubInfo();
      }
    }
    this.regService.getRegionalClubs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('regionalClubs')) {
            this.allClubs = res.regionalClubs;
            if (this.isSuperAdmin) {
              this.workingClubs = this.allClubs;
            } else {
              this.loadMyClubInfo();
            }
          }
        });
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openTab(club: RegionalClub) {

  }

  loadMyClubInfo() {
    const foundMyClub = this.allClubs.filter((aClub) => {
      return aClub.name == this.regionalClubName
    });
    if (foundMyClub.length > 0) {
      this.myClub = foundMyClub[0];
      this.workingClubs = [this.myClub];
    }
  }
  emailClub() {
    // for now
    EditModalComponent.open(this.modalService);
  }

  onUploadFinished(file: FileHolder) {
    // check the response
    this.customErrorMessage = '';
    if (file.serverResponse && file.serverResponse.response.status === 200) {
      this.photoFilePath = JSON.parse(file.serverResponse.response.body).filename;
      this.uploadFailed = false;
    } else {
      this.uploadFailed = true;
      if (file.serverResponse) {
        this.customErrorMessage = JSON.parse(file.serverResponse.response.error).message;
      }
    }
  }

  onRemove(file: FileHolder) {
    if (file.serverResponse) {
      let adPath = JSON.parse(file.serverResponse.response.body).filename;
      this.photoFilePath = '';
    }
  }

  getLogo(club: RegionalClub) {
    return 'assets/images/regclubs/' + club.logoFileName;
  }
}
