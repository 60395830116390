import { Component, NgZone } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RegionalClub } from '../../models/basics.model';
import { Subject, takeUntil } from 'rxjs';
import { TokenStorageService } from '../../services/token-storage.service';
import { RegionalclubService } from '../../services/regionalclub.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewUserModalComponent } from '../add-new-user-modal/add-new-user-modal.component';

@Component({
  selector: 'app-working-dog-online-entry',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './working-dog-online-entry.component.html',
  styleUrl: './working-dog-online-entry.component.scss'
})
export class WorkingDogOnlineEntryComponent {
  allClubs: RegionalClub[] = [];
  workingClubs: RegionalClub[] = [];
  private unsubscribe$ = new Subject<void>();
  isSuperAdmin = false;
  isLiaison = false;
  danzEnabled = true;
  suemEnabled = true;
  mlzEnabled = true;
  saEnabled = true;
  jnEnabled = true;
  enEnabled = true;
  protected regionalClubName = 'Unknown Club Name';
  protected regionalClubAbbr = 'Unknown Club Abbr';
  myClub: RegionalClub = {
    abbreviation: '',
    areaServed: '',
    contactEmail: '',
    contactName: '',
    facebookId: '',
    facebookUrl: '',
    id: 0,
    instagramUrl: '',
    isActive: 0,
    logoFileName: '',
    name: '',
    rescueUrl: '',
    twitterUrl: '',
    websiteUrl: '',
    zipCodes: ''
  }

  public mySites = [
    { id: 1, regClub: 'NCS', name: 'Frontier Park', state: 'draft'},
    { id: 2, regClub: 'NCS', name: 'Richfield Memorial Park', state: 'Waiting'},
    { id: 3, regClub: 'NCS', name: 'Camelot Lake', state: 'Approved'}
  ];
  public allSites = [
    { id: 1, regClub: 'NCS', name: 'Nolte State Park', state: 'live'},
    { id: 2, regClub: 'OWNC', name: 'Dallas Lonestar Park', state: 'live'},
    { id: 3, regClub: 'BMNC', name: 'Bear Mountain Lake', state: 'live'}
  ];
  public myBoats = [
    { id: 1, regClub: 'NCS', tag: 'TBD', state: 'draft'},
    { id: 2, regClub: 'NCS', tag: 'TBD', state: 'Waiting'},
    { id: 3, regClub: 'NCS', tag: 'BT109', state: 'Approved'}
  ];
  public allBoats = [
    { id: 1, regClub: 'NCS', tag: 'BT034', state: 'live'},
    { id: 2, regClub: 'SENC', tag: 'BT091', state: 'live'},
    { id: 3, regClub: 'NCSC', tag: 'BT109', state: 'live'},
    { id: 4, regClub: 'OWNC', tag: 'BT037', state: 'live'},
    { id: 5, regClub: 'PNNC', tag: 'BT041', state: 'live'},
    { id: 6, regClub: 'NCNE', tag: 'BT129', state: 'live'},
    { id: 7, regClub: 'NCNC', tag: 'BT134', state: 'live'},
    { id: 8, regClub: 'NCFL', tag: 'BT061', state: 'live'},
    { id: 9, regClub: 'SCNC', tag: 'BT059', state: 'live'},
    { id: 10, regClub: 'BMNC', tag: 'BT024', state: 'live'},
    { id: 11, regClub: 'SENC', tag: 'BT011', state: 'live'},
    { id: 12, regClub: 'PONC', tag: 'BT149', state: 'live'}
  ];
  public myEvents = [
    { id: 1, regClub: 'NCS', name: 'NCS 2024 Fall Draft Test', state: 'open'},
    { id: 2, regClub: 'NCS', name: 'NCS 2024 Water Test', state: 'open'},
    { id: 3, regClub: 'NCS', name: 'NCS 2023 Fall Draft Test', state: 'closed'}
  ];
  public allEvents = [
    { id: 1, regClub: 'NCS', name: 'NCS 2024 Fall Draft Test', state: 'open'},
    { id: 2, regClub: 'SENC', name: 'SENC 2024  Draft Test', state: 'open'},
    { id: 3, regClub: 'NCSC', name: 'NCSC Fall Draft Test', state: 'open'},
    { id: 4, regClub: 'OWNC', name: 'OWNC 2024 Water Test', state: 'open'},
    { id: 5, regClub: 'PNNC', name: 'PNNC 2024 Fall Draft Test', state: 'closed'},
    { id: 6, regClub: 'NCNE', name: 'NCNE Sprint Draft Test', state: 'closed'},
    { id: 7, regClub: 'NCNC', name: 'NCS 2024 Water Test', state: 'closed'},
    { id: 8, regClub: 'NCFL', name: 'NCFL 2024 Fall Draft Test', state: 'closed'},
    { id: 9, regClub: 'SCNC', name: 'SCNC Draft Test Extravaganza', state: 'closed'},
    { id: 10, regClub: 'BMNC', name: 'NCS 2023 Fall Draft Test', state: 'closed'},
    { id: 11, regClub: 'SENC', name: 'SENC 2023 Draft Test', state: 'closed'},
    { id: 12, regClub: 'PONC', name: 'PONC 2023 Water Test', state: 'closed'}
  ];

  constructor(private tokenService: TokenStorageService,
              private regService: RegionalclubService,
              private modalService: NgbModal,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    const roles = this.tokenService.getRoles();
    if (roles && roles.length > 0) {
      if (roles[0].role === 'superAdmin') {
        this.isSuperAdmin = true;
      } else {
        this.regionalClubAbbr = roles[0].abbr;
        this.regionalClubName = roles[0].name;
        this.loadMyClubInfo();
      }
    }
    this.regService.getRegionalClubs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('regionalClubs')) {
            this.allClubs = res.regionalClubs;
            if (this.isSuperAdmin) {
              this.workingClubs = this.allClubs;
            } else {
              this.loadMyClubInfo();
            }
          }
        });
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addSuperAdmin() {
    AddNewUserModalComponent.open(this.modalService);
  }

  loadMyClubInfo() {
    const foundMyClub = this.allClubs.filter((aClub) => {
      return aClub.name == this.regionalClubName
    });
    if (foundMyClub.length > 0) {
      this.myClub = foundMyClub[0];
      this.workingClubs = [this.myClub];
    }
  }
  addClubAdmin() {
    AddNewUserModalComponent.open(this.modalService);
  }

  gotoDetails(club: RegionalClub) {

  }

  gotoBoatDetails(boat: any) {

  }

  gotoSiteDetails(site: any) {

  }

  manageBoats() {

  }

  manageSites() {

  }

  gotoEventDetails(site: any) {

  }

  manageEvents() {

  }
}
