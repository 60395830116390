<div class="container">
  @if (isSuperAdmin) {
    <div class="row mt-4">
      <div class="col-md-4 d-flex justify-content-evenly align-items-end mb-3">
        <h5>Select a Regional Club</h5>
        <button class="btn btn-secondary" (click)="emailClub()">Email All Clubs</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 card">
        <div class="d-flex">
          <div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills flex-column w-50" orientation="vertical">
            @for (club of workingClubs; track club.id) {
              <li class="card-body" [ngbNavItem]="club.id">
                <button class="w-100 text-start" ngbNavLink>{{ club.name }}</button>
                <ng-template ngbNavContent class="w-100">
                  <h5 class="mt-3 text-center">{{ club.name }}</h5>
                  <div class="text-end mb-2">
                    <p><mat-slide-toggle labelPosition="before" [(ngModel)]="isCompliant">Club is currently Compliant</mat-slide-toggle></p>
                    <button class="btn btn-secondary" (click)="emailClub()">Email Club Contacts</button>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div ngbAccordion [closeOthers]="true">
                        <div ngbAccordionItem [collapsed]="false">
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>Current Personnel</button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p class="fw-bold">Board of Directors</p>
                                    <ul>
                                      <li>President: {{ club.contactName }}</li>
                                      <li>Vice President: {{ club.contactName }}</li>
                                      <li>Treasurer: {{ club.contactName }}</li>
                                      <li>Membership Chair: {{ club.contactName }}</li>
                                      <li>Director #1: {{ club.contactName }}</li>
                                      <li>Director #2: {{ club.contactName }}</li>
                                      <li>Newsletter Editor: {{ club.contactName }}</li>
                                    </ul>
                                    <p class="fw-bold">Representatives</p>
                                    <ul>
                                      <li>Rescue Rep: {{ club.contactName }}</li>
                                      <li>Regional Club Contact: {{ club.contactName }}</li>
                                      <li>Regional Club Liaison: {{ club.contactName }}</li>
                                      <li>Working Dog Admins:
                                        <ul>
                                          <li>{{ club.contactName }}</li>
                                          <li>{{ club.contactName }}</li>
                                          <li>{{ club.contactName }}</li>
                                        </ul>
                                      </li>
                                      <li>Regional Specialty Chair: {{ club.contactName }}</li>
                                    </ul>
                                  </div>
                                  <div class="col-md-6">
                                    <p class="fw-bold">Member Counts</p>
                                    <ul>
                                      <li>Total members: 112</li>
                                      <li>Total that are also NCA Members: 71</li>
                                      <li>Percentage NCA members: {{ (71/112*100).toFixed(2) }}%</li>
                                    </ul>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <div ngbAccordionItem>
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>Links</button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <div class="w-100" style="height: 200px;">
                                  <div class="d-flex h-100">
                                    <img src="{{ getLogo(club) }}" alt="Club Logo"/>
                                    <app-image-upload
                                      [max]="1" class="customUploadClass"
                                      [extensions]="['image/jpg','image/png','image/jpeg','application/pdf','image/tif','image/tiff']"
                                      [url]="'https://images.ncanewfs.org/blob_upload'"
                                      [buttonCaption]="'Select Files to Upload'"
                                      [registrationNumber]=""
                                      [partName]="'blob'"
                                      [serviceType]="'Aot'"
                                      [filebase]="'nt-'"
                                      [maxFileSize]="20 * 1024 * 1024"
                                      (uploadFinished)="onUploadFinished($event)"
                                      (removed)="onRemove($event)"
                                      [dropBoxMessage]="'Drop your images here.'">
                                    </app-image-upload>
                                  </div>
                                </div>
                                <p class="fw-bold">Website Link: <a href="{{ club.websiteUrl }}" target="_blank">{{ club.websiteUrl }}</a></p>
                                <p class="fw-bold">Rescue Link: <a href="{{ club.rescueUrl }}" target="_blank">{{ club.rescueUrl }}</a></p>
                                <p class="fw-bold">Social Media Links</p>
                                <ul>
                                  <li>Facebook: <a href="{{ club.facebookUrl }}" target="_blank">{{ club.facebookUrl }}</a></li>
                                  <li>Instagram: <a href="{{ club.instagramUrl }}" target="_blank">{{ club.instagramUrl }}</a></li>
                                  <li>Twitter: <a href="{{ club.twitterUrl }}" target="_blank">{{ club.twitterUrl }}</a></li>
                                </ul>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <div ngbAccordionItem>
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>Current Documents </button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <p class="fw-bold">Documents</p>
                                <ul>
                                  <li><span class="fw-bold">Minutes:</span> uploaded on <a href="#">12/31/2023</a></li>
                                  <li><span class="fw-bold">Newsletter:</span> uploaded on <a href="#">12/31/2023</a></li>
                                  <li><span class="fw-bold">By-laws:</span> uploaded on <a href="#">12/31/2023</a></li>
                                </ul>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <div ngbAccordionItem>
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>Submitted Club Forms</button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>

                                <p class="fw-bold">History of submitted Regional Club Forms</p>
                                <table class="table table-bordered table-hover">
                                  <tr>
                                    <th>Form</th>
                                    <th>Submit Date</th>
                                    <th>Submitted By</th>
                                  </tr>
                                  <tr>
                                    <td>Working Dog Test Application</td>
                                    <td>12/31/2023</td>
                                    <td>{{ club.contactName }}</td>
                                  </tr>
                                  <tr>
                                    <td>Regional Specialty Application</td>
                                    <td>12/29/2022</td>
                                    <td>{{ club.contactName }}</td>
                                  </tr>
                                  <tr>
                                    <td>AKC Performance Test Application</td>
                                    <td>1/5/2022</td>
                                    <td>{{ club.contactName }}</td>
                                  </tr>
                                  <tr>
                                    <td>Working Dog Test Application</td>
                                    <td>12/31/2021</td>
                                    <td>{{ club.contactName }}</td>
                                  </tr>
                                </table>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <div ngbAccordionItem>
                          <h2 ngbAccordionHeader>
                            <button ngbAccordionButton>Club Details</button>
                          </h2>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>

                                <p class="fw-bold">Important Club Details</p>
                                <table class="table table-bordered table-hover">
                                  <tr>
                                    <th>Data Type</th>
                                    <th>Value</th>
                                  </tr>
                                  <tr>
                                    <td>IRS Tax ID</td>
                                    <td>38295578</td>
                                  </tr>
                                  <tr>
                                    <td>Bank</td>
                                    <td>Harris Savings and Load</td>
                                  </tr>
                                  <tr>
                                    <td>Bank Account Number</td>
                                    <td>700092358172732</td>
                                  </tr>
                                  <tr>
                                    <td>PayPal Email Address</td>
                                    <td>treasurer&#64;regionalclub.org</td>
                                  </tr>
                                </table>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            }

          </div>

          <div [ngbNavOutlet]="nav" class="ms-4"></div>
        </div>

      </div>
    </div>
  } @else {
    <div class="row">
      <div class="col-md-10 card">
      <h5 class="mt-3 text-center">{{ myClub.name }}</h5>
        <div ngbAccordion [closeOthers]="true">
          <div ngbAccordionItem [collapsed]="false">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>Current Personnel</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="row">
                    <div class="col-md-12 text-end mb-2">
                      <button class="btn btn-secondary" (click)="emailClub()">Edit Personnel</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="fw-bold">Board of Directors</p>
                      <ul>
                        <li>President: {{ myClub.contactName }}</li>
                        <li>Vice President: {{ myClub.contactName }}</li>
                        <li>Treasurer: {{ myClub.contactName }}</li>
                        <li>Membership Chair: {{ myClub.contactName }}</li>
                        <li>Director #1: {{ myClub.contactName }}</li>
                        <li>Director #2: {{ myClub.contactName }}</li>
                        <li>Newsletter Editor: {{ myClub.contactName }}</li>
                      </ul>
                      <p class="fw-bold">Representatives</p>
                      <ul>
                        <li>Rescue Rep: {{ myClub.contactName }}</li>
                        <li>Regional Club Contact: {{ myClub.contactName }}</li>
                        <li>Regional Club Liaison: {{ myClub.contactName }}</li>
                        <li>Working Dog Admins:
                          <ul>
                            <li>{{ myClub.contactName }}</li>
                            <li>{{ myClub.contactName }}</li>
                            <li>{{ myClub.contactName }}</li>
                          </ul>
                        </li>
                        <li>Regional Specialty Chair: {{ myClub.contactName }}</li>
                      </ul>
                    </div>
                    <div class="col-md-6">
                      <p class="fw-bold">Member Counts</p>
                      <ul>
                        <li>Total members: 112</li>
                        <li>Total that are also NCA Members: 71</li>
                        <li>Percentage NCA members: {{ (71/112*100).toFixed(2) }}%</li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>Links</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="row">
                    <div class="col-md-12 text-end mb-2">
                      <button class="btn btn-secondary" (click)="emailClub()">Update Links</button>
                    </div>
                  </div>
                  <div class="w-100 text-center border-top border-bottom" style="height: 200px;">
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Current Logo</h6>
                        <img src="{{ getLogo(myClub) }}" alt="Club Logo"/>
                      </div>
                      <div class="col-md-6">
                        <app-image-upload
                          [max]="1" class="customUploadClass"
                          [extensions]="['image/jpg','image/png','image/jpeg','application/pdf','image/tif','image/tiff']"
                          [url]="'https://images.ncanewfs.org/blob_upload'"
                          [buttonCaption]="'Select a File to Upload'"
                          [registrationNumber]=""
                          [partName]="'blob'"
                          [serviceType]="'Aot'"
                          [filebase]="'nt-'"
                          [maxFileSize]="20 * 1024 * 1024"
                          (uploadFinished)="onUploadFinished($event)"
                          (removed)="onRemove($event)"
                          [dropBoxMessage]="'Drop your Logo image here.'">
                        </app-image-upload>
                      </div>
                    </div>
                  </div>
                  <p class="fw-bold mt-4">Website Link: <a href="{{ myClub.websiteUrl }}" target="_blank">{{ myClub.websiteUrl }}</a></p>
                  <p class="fw-bold">Rescue Link: <a href="{{ myClub.rescueUrl }}" target="_blank">{{ myClub.rescueUrl }}</a></p>
                  <p class="fw-bold">Social Media Links</p>
                  <ul>
                    <li>Facebook: <a href="{{ myClub.facebookUrl }}" target="_blank">{{ myClub.facebookUrl }}</a></li>
                    <li>Instagram: <a href="{{ myClub.instagramUrl }}" target="_blank">{{ myClub.instagramUrl }}</a></li>
                    <li>Twitter: <a href="{{ myClub.twitterUrl }}" target="_blank">{{ myClub.twitterUrl }}</a></li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>Current Documents </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="row">
                    <div class="col-md-12 text-end mb-2">
                      <button class="btn btn-secondary" (click)="emailClub()">Update Documents</button>
                    </div>
                  </div>
                  <p class="fw-bold">Documents</p>
                  <ul>
                    <li><span class="fw-bold">Minutes:</span> uploaded on <a href="#">12/31/2023</a></li>
                    <li><span class="fw-bold">Newsletter:</span> uploaded on <a href="#">12/31/2023</a></li>
                    <li><span class="fw-bold">By-laws:</span> uploaded on <a href="#">12/31/2023</a></li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>Submitted Club Forms</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="row">
                    <div class="col-md-12 text-end mb-2">
                      <button class="btn btn-secondary" (click)="emailClub()">Submit New Forms</button>
                    </div>
                  </div>

                  <p class="fw-bold">History of submitted Regional Club Forms</p>
                  <table class="table table-bordered table-hover">
                    <tr>
                      <th>Form</th>
                      <th>Submit Date</th>
                      <th>Submitted By</th>
                    </tr>
                    <tr>
                      <td>Working Dog Test Application</td>
                      <td>12/31/2023</td>
                      <td>{{ myClub.contactName }}</td>
                    </tr>
                    <tr>
                      <td>Regional Specialty Application</td>
                      <td>12/29/2022</td>
                      <td>{{ myClub.contactName }}</td>
                    </tr>
                    <tr>
                      <td>AKC Performance Test Application</td>
                      <td>1/5/2022</td>
                      <td>{{ myClub.contactName }}</td>
                    </tr>
                    <tr>
                      <td>Working Dog Test Application</td>
                      <td>12/31/2021</td>
                      <td>{{ myClub.contactName }}</td>
                    </tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </div>
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>Club Details</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>

                  <p class="fw-bold">Important Club Details</p>
                  <table class="table table-bordered table-hover">
                    <tr>
                      <th>Data Type</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>IRS Tax ID</td>
                      <td>38295578</td>
                    </tr>
                    <tr>
                      <td>Bank</td>
                      <td>Harris Savings and Load</td>
                    </tr>
                    <tr>
                      <td>Bank Account Number</td>
                      <td>700092358172732</td>
                    </tr>
                    <tr>
                      <td>PayPal Email Address</td>
                      <td>treasurer&#64;regionalclub.org</td>
                    </tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  <!--
  <div class="row">
    <div class="col-md-4">
      <h4 class="p-3">Select a Regional Club</h4>

      <ul class="nav nav-tabs d-flex flex-column" id="clubTab" role="tablist">
        @for (club of allClubs; track club.id) {
          <li class="nav-item" role="presentation">
            <button class="nav-link" [id]="club.id" data-bs-toggle="tab" data-bs-target="#{{club.id}}" type="button" role="tab">{{ club.name }}</button>
          </li>
        }
      </ul>
    </div>
    <div class="col-md-8">
      @for (subClub of allClubs; track subClub.id) {
        <div [id]="subClub.id" class="tab-content p-2">
          <h5>Details for {{ subClub.name }}</h5>
        </div>
      }
    </div>
  </div>
  -->
</div>
