@if (useHeader) {
<div class="header">
  <div class="container">
    @if (useLargeHeader) {
      <div class="logo" ><img src="assets/images/NCAtrans900909.png" width="190" height="183" alt="NCA logo"></div>
    }
    <div class="d-none d-sm-block header-right">

      <span class="menu"></span>
      <div class="top-menu search-panel mb-3">
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a href="https://members.ncanewfs.org/calendar/">Events</a></li>
          <li><a href="https://members.ncanewfs.org">Join The NCA</a></li>
          <li><a href="https://members.ncanewfs.org/members">Membership Portal</a></li>
          <li><a href="https://www.ncanewfs.org/regionalclubs.html">Regional Clubs</a></li>
          <li><a href="https://www.ncanewfs.org/contacts.html">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <div class="d-block d-sm-none header-right">

      <span class="menu" data-bs-toggle="collapse" data-bs-target="#hamburger"></span>
      <div id="hamburger" class="top-menu collapse search-panel mb-3">
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a href="https://members.ncanewfs.org/calendar/">Events</a></li>
          <li><a href="https://members.ncanewfs.org">Join The NCA</a></li>
          <li><a href="https://members.ncanewfs.org/members">Membership Portal</a></li>
          <li><a href="https://www.ncanewfs.org/regionalclubs.html">Regional Clubs</a></li>
          <li><a href="https://www.ncanewfs.org/contacts.html">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
}
