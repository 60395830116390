<div
  fileDrop
  [accept]="extensions"
  (fileOver)="onFileOver($event)"
  (fileDrop)="onFileChange($event)"
  [ngClass]="{'img-ul-file-is-over': fileOver}"
  [ngStyle]="style?.layout || null"
>
  <div class="img-ul-file-upload img-ul-hr-inline-group">
    @if (fileCounter != max) {
      <div>
        <label
          class="img-ul-upload img-ul-button"
          [ngStyle]="style?.selectButton || null"
          [ngClass]="{'img-ul-disabled': disabled}"
        >
          <span [innerText]="buttonCaption"></span>
          <input
            type="file"
            [disabled]="disabled"
            [accept]="extensions"
            multiple (change)="onFileChange(input.files)"
            #input>
        </label>
        <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
      </div>
    }
    @if (fileCounter > 0) {
      <button
        [disabled]="disabled"
        class="img-ul-clear img-ul-button"
        (click)="deleteAll()"
        [ngStyle]="style?.clearButton || null"
        [innerText]="clearButtonCaption">
      </button>
    }
  </div>

  @if (showFileTooLargeMessage) {
    <p class="img-ul-file-too-large"></p>
  }
  @if (preview) {
    <div
      class="img-ul-container img-ul-hr-inline-group"
      [ngStyle]="style?.previewPanel || null"
    >
      @for (file of files; track file.src) {
        <div>
          @if (!file.isPdf && !file.isTiff) {
            <div
              class="img-ul-image"
              matTooltip="Click on the image to view it full-size."
              (click)="previewFileClicked(file)"
              [ngStyle]="{'background-image': 'url('+ file.src +')'}">
              @if (file.pending) {
                <div class="img-ul-loading-overlay">
                  <div class="img-ul-spinning-circle"></div>
                </div>
              }
            </div>
          }
          @if (!file.isPdf && file.isTiff) {
            <div class="img-ul-image"
                 matTooltip="Click on the image to view it full-size."
                 (click)="previewFileClicked(file)"
                 [ngStyle]="{'background-image': 'url(/assets/no_preview.png)'}">
              @if (file.pending) {
                <div class="img-ul-loading-overlay">
                  <div class="img-ul-spinning-circle"></div>
                </div>
              }
              <p>TIFF file uploaded successfully</p>
            </div>
          }
          @if (file.isPdf) {
            <div class="img-ul-image">
              <pdf-viewer
                class="img-ul-pdf-image"
                [src]="file.pdfSrc"
                [original-size]="false"
                (click)="previewPDFFileClicked()">
              </pdf-viewer>
              @if (file.pending) {
                <div class="img-ul-loading-overlay">
                  <div class="img-ul-spinning-circle"></div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
