import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { RegionalClubs } from '../models/basics.model';
const AUTH_API = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
/*
const httpFormOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};
*/

@Injectable({
  providedIn: 'root'
})
export class RegionalclubService {

  private regClubs$: BehaviorSubject<RegionalClubs>;

  constructor(private http: HttpClient,
              private ngZone: NgZone) {
    this.regClubs$ = new BehaviorSubject<RegionalClubs>({regionalClubs: []});
  }

  public getRegionalClubs() : BehaviorSubject<RegionalClubs> {
    return this.regClubs$;
  }

  public loadRegionalClubs() {
    this.readRegionalClubs();
  }

  readRegionalClubs() {
    this.http.get(AUTH_API + '/regionalclubs', httpOptions)
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('regionalClubs')) {
            let workingClubs: RegionalClubs = <RegionalClubs>res;
            workingClubs.regionalClubs.map(aClub => {
              if (aClub.contactEmail.startsWith('mailto:')) {
                aClub.contactEmail = aClub.contactEmail.substring(7);
              }
            })
            this.regClubs$.next(workingClubs)
          }
        })
      })
  }
}
