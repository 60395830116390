import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-auth-sign-up-button',
  standalone: true,
  imports: [],
  template: `
    <button  class="btn btn-secondary btn-block mb-2" (click)="handleSignUp()">Sign Up</button>
  `,
  styleUrl: './auth-sign-up-button.component.scss'
})
export class AuthSignUpButtonComponent {
  private auth = inject(AuthService);
  handleSignUp(): void {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }

}
