<div class="container-fluid">
  @if (!isSuperAdmin) {
  <div class="row">
    <div class="d-block col-md-10 mt-2">
      <h3 class="">Welcome {{ myClub.name }} Admin</h3>
    </div>
  </div>
  }

  <div class="row">
    <div class="d-block mx-auto col-md-10 table-responsive">
      <h4>All Boats</h4>
      <table class="table table-bordered table-hover table-sm events-table">
        <thead>
        <tr>
          <th style="display: none;">ID</th>
          <th>Regional Club</th>
          <th>Tag</th>
          <th>State</th>
        </tr>
        </thead>
        <tbody class="text-nowrap">
          @if (isSuperAdmin) {
            @for (boat of allBoats; track boat.id) {
              <tr (click)="gotoBoatDetails(boat)">
                <td style="display: none;">{{ boat.id }}</td>
                <td>{{ boat.regClub }}</td>
                <td>{{ boat.tag }}</td>
                <td class="text-center">{{ boat.state }}</td>
              </tr>
            }
          } @else {
            @for (boat of myBoats; track boat.id) {
              <tr (click)="gotoBoatDetails(boat)">
                <td style="display: none;">{{ boat.id }}</td>
                <td>{{ boat.regClub }}</td>
                <td>{{ boat.tag }}</td>
                <td class="text-center">{{ boat.state }}</td>
              </tr>
            }
          }
        </tbody>
      </table>
      <button class="btn btn-primary mb-3" (click)="manageBoats()">Manage Boats</button>
    </div>
  </div>
  <div class="row">
    <div class="d-block mx-auto col-md-10 table-responsive">
      <h4>All Locations</h4>
      <table class="table table-bordered table-hover table-sm events-table">
        <thead>
        <tr>
          <th style="display: none;">ID</th>
          <th>Event name</th>
          <th>Regional Club</th>
          <th>State</th>
        </tr>
        </thead>
        <tbody class="text-nowrap">
          @if (isSuperAdmin) {
            @for (site of allSites; track site.id) {
              <tr (click)="gotoSiteDetails(site)">
                <td style="display: none;">{{ site.id }}</td>
                <td class="long-text">{{ site.name }}</td>
                <td>{{ site.regClub }}</td>
                <td class="text-center">{{ site.state }}</td>
              </tr>
            }
          } @else {
            @for (site of mySites; track site.id) {
              <tr (click)="gotoSiteDetails(site)">
                <td style="display: none;">{{ site.id }}</td>
                <td class="long-text">{{ site.name }}</td>
                <td>{{ site.regClub }}</td>
                <td class="text-center">{{ site.state }}</td>
              </tr>
            }
          }

        </tbody>
      </table>
      <button class="btn btn-primary mb-3" (click)="manageSites()">Manage Sites</button>
    </div>
  </div>
  <div class="row">
    <div class="d-block mx-auto col-md-10 table-responsive">
      <h4>All Working Dog Events for {{ regionalClubAbbr }}</h4>
      <table class="table table-bordered table-hover table-sm events-table">
        <thead>
        <tr>
          <th style="display: none;">ID</th>
          <th>Event name</th>
          <th>Open/Closed</th>
        </tr>
        </thead>
        <tbody class="text-nowrap">
          @if (isSuperAdmin) {
            @for (event of allEvents; track event.id) {
              <tr (click)="gotoEventDetails(event)">
                <td style="display: none;">{{ event.id }}</td>
                <td class="long-text">{{ event.name }}</td>
                <td>{{ event.state }}</td>
              </tr>
            }
          } @else {
            @for (event of myEvents; track event.id) {
              <tr (click)="gotoEventDetails(event)">
                <td style="display: none;">{{ event.id }}</td>
                <td class="long-text">{{ event.name }}</td>
                <td>{{ event.state }}</td>
              </tr>
            }
          }

        </tbody>
      </table>
      <button class="btn btn-primary mb-3" (click)="manageEvents()">Manage Events</button>
    </div>
  </div>
</div>
