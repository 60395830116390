import { Component } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

class EventService {
}

@Component({
  selector: 'app-edit-modal',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatProgressSpinner
  ],
  templateUrl: './edit-modal.component.html',
  styleUrl: './edit-modal.component.scss'
})
export class EditModalComponent {
  formGroup: UntypedFormGroup;
  firstControl = new UntypedFormControl('', Validators.required);
  lastControl = new UntypedFormControl('', Validators.required);
  saving = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.formGroup = this.formBuilder.group({
      firstNameControl: this.firstControl,
      lastNameControl: this.lastControl,
    });
  }

  static open(modalService: NgbModal) {
    const options: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: true,
      scrollable: true,
      size: 'med',
    };
    return modalService.open(EditModalComponent, options);
  }

  onCancel() {
    this.activeModal.close();
  }

  async onOK() {
    this.saving = false;
    this.activeModal.close();
  }

}
