import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TokenStorageService } from './services/token-storage.service';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'regionalclubportal';
  isLoggedIn: boolean = false;
  userEmail?: string;
  isLoginFailed = false;
  errorMessage = '';

  constructor(private tokenStorage: TokenStorageService,
              private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    const aToken = this.tokenStorage.getToken();
    if (aToken) {
      // forces the hasToken field to set properly
      this.authService.validateToken().subscribe({
        next: data => {
          this.tokenStorage.saveToken(aToken);
          this.isLoggedIn = true;
          const user = this.tokenStorage.getUser();
          this.userEmail = user.email;
          this.isLoginFailed = false;
        }, error: err => {
          // we got here because the token didn't validate so login as a guest again
          // login a guest
//          this.authService.guestLogin().subscribe({
//            next: data => {
//              this.tokenStorage.saveToken(data.token);
//              this.tokenStorage.saveUser(data);
//              this.isLoggedIn = true;
//              this.isLoginFailed = false;
 //           },
 //           error: err => {
              this.errorMessage = err.error.message;
              this.isLoggedIn = false;
              this.isLoginFailed = true;
            }
   //       });
     //   }
      })
    } else {
      this.isLoggedIn = false;
      /*
      this.authService.guestLogin().subscribe({
        next: data => {
          this.tokenStorage.saveToken(data.token);
          this.tokenStorage.saveUser(data);
          this.isLoggedIn = true;
          this.isLoginFailed = false;
        },
        error: err => {
          this.errorMessage = err.error.message;
          this.isLoggedIn = false;
          this.isLoginFailed = true;
        }
      });

       */
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      //Do something with the NavigationEnd event object.
      const aToken = this.tokenStorage.getToken();
      this.isLoggedIn = !!aToken;
      if (this.isLoggedIn) {
        const user = this.tokenStorage.getUser();
        this.userEmail = user.email;
      } else {
        /*
        this.authService.guestLogin().subscribe({
          next: data => {
            this.tokenStorage.saveToken(data.token);
            this.tokenStorage.saveUser(data);
            this.isLoggedIn = true;
            this.isLoginFailed = false;
          },
          error: err => {
            this.errorMessage = err.error.message;
            this.isLoggedIn = false;
            this.isLoginFailed = true;
          }
        });

         */
      }
    });


  }

  logout() {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
