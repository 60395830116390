import { Component } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';

@Component({
  selector: 'app-add-new-user-modal',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatProgressSpinner,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatRadioGroup,
    MatRadioButton
  ],
  templateUrl: './add-new-user-modal.component.html',
  styleUrl: './add-new-user-modal.component.scss'
})
export class AddNewUserModalComponent {
  formGroup: UntypedFormGroup;
  firstControl = new UntypedFormControl('', Validators.required);
  lastControl = new UntypedFormControl('', Validators.required);
  emailControl = new UntypedFormControl('', Validators.required);
  roleControl = new UntypedFormControl('', Validators.required);
  saving = false;
  roles = [
    {id: 0, name: 'None', description: 'A user who has no Admin access'},
    {id: 1, name: 'Regional Specialty Admin', description: 'A user who can manage Regional Specialty applications'},
    {id: 2, name: 'Boat/Location Admin', description: 'A user who can manage boats an locations for a regional club'},
    {id: 3, name: 'Club Admin', description: 'A user who can administer the club settings, documents and apply for Working Dog events'},
    {id: 4, name: 'Club Liaison', description: 'A user who represents a regional club to the Regional Club Committee and can invite other club members'},
    {id: 5, name: 'Super Admin', description: 'A user who has complete Admin access to all clubs'}
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.formGroup = this.formBuilder.group({
      firstControl: this.firstControl,
      lastControl: this.lastControl,
      emailControl: this.lastControl,
      roleControl: this.lastControl,
    });
  }

  static open(modalService: NgbModal) {
    const options: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      keyboard: true,
      scrollable: true,
      size: 'med',
    };
    return modalService.open(AddNewUserModalComponent, options);
  }

  onCancel() {
    this.activeModal.close();
  }

  async onOK() {
    this.saving = false;
    this.activeModal.close();
  }

}
