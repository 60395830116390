import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TokenStorageService } from './services/token-storage.service';
import { AuthService } from './services/auth.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { authInterceptorProviders } from './services/auth.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { RegionalclubService } from './services/regionalclub.service';
import { AuthModule, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { ImageService } from './services/image.service';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(AuthModule.forRoot({
      ...environment.auth0,
    })),provideRouter(routes), provideAnimationsAsync(), TokenStorageService, AuthService,
    AuthGuardService, RegionalclubService, ImageService,
    authInterceptorProviders, provideHttpClient(
      withInterceptorsFromDi()
    )]
};
