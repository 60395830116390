// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://regionalclubportaltest.ncanewfs.org/admin',
//  baseURL: 'http://localhost:4200',
  apiUrl: 'https://memberapitest.ncanewfs.org/api',
  auth0: {
    domain: 'dev-xlm20i6dgyro2fuo.us.auth0.com',
    clientId: 'WxIsjqu0Ou2Tr7j8imAgrHwYYUutg7LD',
    authorizationParams: {
      redirect_uri: 'https://regionalclubportaltest.ncanewfs.org/admin',
    },
    errorPath: '/callback',
  },
  api: {
    serverUrl: 'https://memberapitest.ncanewfs.org/api',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
