<div id="app">
  <div class="footer-wrapper">
    <app-header></app-header>
    <div class="container-fluid">
      @if (isLoginFailed) {
      <div
        class="alert alert-danger"
        role="alert"
      >
        Login failed: {{ errorMessage }}
      </div>
      }
      <router-outlet></router-outlet>
    </div>
    <div class="push"></div>
  </div>
  <app-footer></app-footer>

</div>
