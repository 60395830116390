import { Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MenuItems } from '../../models/basics.model';
import { AsyncPipe, DOCUMENT, TitleCasePipe } from '@angular/common';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenavContent,
    AsyncPipe,
    MatSidenav,
    MatToolbar,
    MatNavList,
    MatListItem,
    RouterLink,
    MatIcon,
    MatIconButton,
    RouterOutlet,
    TitleCasePipe
  ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss'
})

export class NavComponent implements OnInit, OnDestroy {

  loggedInEmail = '';
  private unsubscribe$ = new Subject<void>();
  private auth = inject(AuthService);
  private doc = inject(DOCUMENT);
  menuItems: MenuItems[] = [];
  isAuthenticated$ = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              private ngZone: NgZone,
              private tokenService: TokenStorageService) {
    const curUser = tokenService.getUser();
    if (curUser.hasOwnProperty('email')) {
      this.loggedInEmail = curUser.email;
    }
  }

  ngOnInit() {
    this.auth.isAuthenticated$.subscribe((isAuth) => {
      this.ngZone.run(() => {
        this.isAuthenticated$ = isAuth
      })
    })
    this.tokenService.getLoggedInNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (this.tokenService.userHasRole('superAdmin')) {
            this.menuItems = [{ name: 'Dashboard', path: 'dashboard', id: 0},
              { name: 'Manage Clubs', path: 'clubs', id: 1},
              { name: 'Manage Users', path: 'users', id: 2},
              { name: 'Manage WDOES', path: 'wdoe', id: 3}];
          } else if (this.tokenService.userHasRole('committeeAdmin')) {
            this.menuItems = [{ name: 'Dashboard', path: 'dashboard', id: 0},
              { name: 'Manage My Club', path: 'clubs', id: 1},
              { name: 'Manage Club Users', path: 'users', id: 2},
              { name: 'Manage WDOES', path: 'wdoe', id: 3}];
          } else if (this.tokenService.userHasRole('clubAdmin')) {
            this.menuItems = [{ name: 'Dashboard', path: 'dashboard', id: 0},
              { name: 'Manage My Club', path: 'clubs', id: 1},
              { name: 'Manage WDOES', path: 'wdoe', id: 3}];
          } else if (this.tokenService.userHasRole('boatLocationAdmin')) {
            this.menuItems = [{ name: 'Dashboard', path: 'dashboard', id: 0},
              { name: 'Manage Boats', path: 'personnel', id: 1},
              { name: 'Manage Locations', path: 'reports', id: 2}];
          } else /* regional specialty admin */ {
            this.menuItems = [{ name: 'Dashboard', path: 'dashboard', id: 0},
              { name: 'Manage Regional Specialty', path: 'specialty', id: 1}];
          }
        });
      });
  }

  logout() {
    if (this.isAuthenticated$) {
      this.auth.logout({
        logoutParams: {
          returnTo: this.doc.location.origin,
          client_id: environment.auth0.clientId
        },
      });
    } else {
      this.tokenService.signOut();
      this.router.navigate(['/', 'login'], {queryParams: {retUrl: 'admin'}});
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
