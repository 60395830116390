import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Subject, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../card/card.component';
import { RegionalClub } from '../../models/basics.model';
import { RegionalclubService } from '../../services/regionalclub.service';

@Component({
  selector: 'app-dash',
  standalone: true,
  imports: [
    MatGridList,
    AsyncPipe,
    MatGridTile,
    CardComponent
  ],
  templateUrl: './dash.component.html',
  styleUrl: './dash.component.scss'
})
export class DashComponent implements OnInit, OnDestroy {

  protected regionalClubName = 'Unknown Club Name';
  protected regionalClubAbbr = 'Unknown Club Abbr';
  myClub: RegionalClub = {
    abbreviation: '',
    areaServed: '',
    contactEmail: '',
    contactName: '',
    facebookId: '',
    facebookUrl: '',
    id: 0,
    instagramUrl: '',
    isActive: 0,
    logoFileName: '',
    name: '',
    rescueUrl: '',
    twitterUrl: '',
    websiteUrl: '',
    zipCodes: ''
  }
  isSuperAdmin = false;
  logoFileName = '';
  private unsubscribe$ = new Subject<void>();
  allClubs: RegionalClub[] = [];

  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: this.isSuperAdmin ? 4 : 2, rows: 4 },
      };
    })
  );
  constructor(private tokenService: TokenStorageService,
              private breakpointObserver: BreakpointObserver,
              private regService: RegionalclubService,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.regService.getRegionalClubs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('regionalClubs')) {
            this.allClubs = res.regionalClubs;
            this.loadMyClubInfo();
          }
        });
      });
    this.regService.loadRegionalClubs();

    const roles = this.tokenService.getRoles();
    if (roles && roles.length > 0) {
      if (roles[0].role === 'superAdmin') {
        this.isSuperAdmin = true;
      } else {
        this.regionalClubAbbr = roles[0].abbr;
        this.regionalClubName = roles[0].name;
        this.loadMyClubInfo();
      }
    }
  }

  loadMyClubInfo() {
    const foundMyClub = this.allClubs.filter((aClub) => {
      return aClub.name == this.regionalClubName
    });
    if (foundMyClub.length > 0) {
      this.myClub = foundMyClub[0];
      this.logoFileName = 'assets/images/regclubs/' + this.myClub.logoFileName;
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
