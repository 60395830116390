<div class="modal-header">
  <h4 class="modal-title">Edit Some Stuff</h4>
</div>

<div class="modal-body">
  <form [formGroup]="formGroup">

    <!--
      This off=screen input is here to keep from auto-focusing on the date select
      element, which was causing mar-form-field to change its properties, which was
      causing Angular to throw a change-detection exception.
    -->
    <input style="position: absolute; left: -9000px; height: 0; width: 0;" />

    <mat-form-field class="d-block">
      <input matInput placeholder="First Name" [formControl]="firstControl" (keyup.Enter)="onOK()">
    </mat-form-field>
    <mat-form-field class="d-block">
      <input matInput placeholder="Last Name" [formControl]="lastControl" (keyup.Enter)="onOK()">
    </mat-form-field>
    <mat-form-field class="d-block">
      <input matInput placeholder="Email" [formControl]="emailControl" (keyup.Enter)="onOK()">
    </mat-form-field>
    <label id="example-radio-group-label">Select a Role</label>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        formControlName="favoriteSeason">
        @for (role of roles; track role.id) {
          <mat-radio-button class="example-radio-button" [value]="role.id"><span class="fw-bold">{{role.name}}</span> - {{ role.description }}</mat-radio-button>
        }
      </mat-radio-group>

    </form>
  @if (saving) {
    <div>
      <mat-spinner class="mx-auto"></mat-spinner>
    </div>
  }
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="onCancel()">Cancel</button>
  <button type="button" class="btn btn-sm btn-secondary" (click)="onOK()">OK</button>
</div>
