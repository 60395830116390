import { Component, NgZone, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { FormsModule } from '@angular/forms';
import { AuthButtonComponent } from '../auth-button/auth-button.component';
import { AuthSignUpButtonComponent } from '../auth-sign-up-button/auth-sign-up-button.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    AuthButtonComponent,
    AuthSignUpButtonComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  form: any = {
    email: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  private unsubscribe$ = new Subject<void>();
  retUrl: string | null = null;

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private tokenStorage: TokenStorageService,
              private router: Router,
              private ngZone: NgZone) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe(params => {
        this.retUrl = params.get('retUrl');
        console.log( 'LoginComponent/ngOnInit '+ this.retUrl);
      });
    this.tokenStorage.getHasTokenNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          const curUser = this.tokenStorage.getUser();
          if (curUser.waId) {
            this.isLoggedIn = true;
            /*
            let url = this.tokenStorage.getRedirectUrl();
            console.log('Redirect Url:' + url);
            if (url) {
              if (url.indexOf('?') >= 0) {
                //const path = url.substring(0, url.indexOf('?') + 1);
                const urlTree = this.router.parseUrl(url);
                this.router.navigateByUrl(urlTree);
              } else {
                this.router.navigate([url]);
              }
            } else {

             */
            console.log( 'return to '+ this.retUrl);
            if (this.retUrl!=null) {
              this.router.navigate( ['/', this.retUrl]);
            } else {
              this.router.navigate( ['home']);
            }

            // }
          }
        });
      });


  }

  onSubmit(): void {
    const { email, password } = this.form;

    this.authService.login(email, password, 'true').subscribe({
      next: data => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        /*
        let url = this.tokenStorage.getRedirectUrl();
        console.log('Redirect Url:' + url);
        if (url.indexOf('?') >= 0) {
          //const path = url.substring(0, url.indexOf('?') + 1);
          const urlTree = this.router.parseUrl(url);
          this.router.navigateByUrl(urlTree);
        } else {
          this.router.navigate([url]);
        }

         */
        console.log( 'return to '+ this.retUrl);
        if (this.retUrl!=null) {
          this.router.navigate( [this.retUrl]);
        } else {
          this.router.navigate( ['home']);
        }

      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  reloadPage(): void {
    window.location.reload();
  }

}
