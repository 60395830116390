@if (isSuperAdmin) {
  <div class="container">
    <div class="row p-3">
      <div class="col-md-6">
        <p class="fw-bold">List of all Super Admins</p>
        <table class="table table-bordered table-hover w-100 superAdminTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Dan Zimmerman</td>
              <td>dan&#64;dogfishsoftware.com</td>
              <td><mat-slide-toggle [(ngModel)]="danzEnabled"></mat-slide-toggle></td>
            </tr>
            <tr>
              <td>Sue Marino</td>
              <td>chnewf14&#64;gmail.com</td>
              <td><mat-slide-toggle [(ngModel)]="suemEnabled"></mat-slide-toggle></td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-primary" (click)="addSuperAdmin()">Add New Super Admin</button>
      </div>
    </div>
    <div class="row p-3 border-top">
      <div class="col-md-10">
        <p class="d-flex justify-content-between"><span class="fw-bold">List of all Regional Club Liaisons</span>
          <span>Use the &quot;Manage Clubs&quot; screen to edit this list</span></p>
        <table class="table table-bordered table-hover w-100 superAdminTable">
          <thead>
          <tr>
            <th>Regional Club</th>
            <th>Name</th>
            <th>Email</th>
            <th>Enabled</th>
          </tr>
          </thead>
          <tbody>
          @for (aClub of allClubs; track aClub.id) {
            <tr>
              <td>{{ aClub.name }}</td>
              <td>{{ aClub.contactName }}</td>
              <td>{{ aClub.contactEmail }}</td>
              <td><mat-slide-toggle [(ngModel)]="danzEnabled"></mat-slide-toggle></td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    </div>
  </div>
} @else {
  <div class="container">
    <div class="row p-3">
      <div class="col-md-10">
        <p class="fw-bold">List of all {{ myClub.name }} Portal Users</p>
        <table class="table table-bordered table-hover w-100 clubAdminTable">
          <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Enabled</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Marylou Zimmerman</td>
            <td>marylouz&#64;marylouz.com</td>
            <td>Club Admin</td>
            <td><mat-slide-toggle [(ngModel)]="mlzEnabled"></mat-slide-toggle></td>
          </tr>
          <tr>
            <td>Dan Zimmerman</td>
            <td>drzimmerman&#64;marylouz.com</td>
            <td>Club Admin</td>
            <td><mat-slide-toggle [(ngModel)]="danzEnabled"></mat-slide-toggle></td>
          </tr>
          <tr>
            <td>Susan Abramski</td>
            <td>sabramski&#64;comcast.net</td>
            <td>Working Dog Club Admin</td>
            <td><mat-slide-toggle [(ngModel)]="saEnabled"></mat-slide-toggle></td>
          </tr>
          <tr>
            <td>Jo Nussle</td>
            <td>newfmagic&#64;comcast.net</td>
            <td>Working Dog Club Admin</td>
            <td><mat-slide-toggle [(ngModel)]="jnEnabled"></mat-slide-toggle></td>
          </tr>
          <tr>
            <td>Eric Nussle</td>
            <td>newfmagic&#64;comcast.net</td>
            <td>Club Boat/Location Admin</td>
            <td><mat-slide-toggle [(ngModel)]="enEnabled"></mat-slide-toggle></td>
          </tr>
          <tr>
            <td>Kristin Eberly</td>
            <td>kristin&#64;eberly.net</td>
            <td>Regional Specialty Admin</td>
            <td><mat-slide-toggle [(ngModel)]="saEnabled"></mat-slide-toggle></td>
          </tr>
          </tbody>
        </table>
        <button class="btn btn-primary" (click)="addClubAdmin()">Add New Club Admin</button>
      </div>
    </div>
  </div>
}
