<div class="banner">
  <div class="container">
    <div class="banner-info">
      <h3><a routerLink="/admin">Enter the Portal</a></h3>
      <p align="left">&nbsp;</p>
    </div>
  </div>
</div>
<div class="content">
  <div class="services-section">
    <div class="container">
      <h3>NCA Regional Club Portal</h3>
      <blockquote>The Newfoundland Club of America (NCA) was founded in 1930 the preserve, protect and promote the Newfoundland Dog. Regional clubs are the ..... </blockquote>
      <div class="services-section-grids">
        <div class="col-lg-4 services-section-grid1">
          <div class="services-section-grid1-top">
            <h4>Option 1</h4>
            <p><a href="https://www.newfpuppy.com"><strong>Something about 1</strong></a></p>
            <p>More about 1.</p>
          </div>
          <div class="services-section-grid1-bottom" style="background: #747270;">
            <h4>All About The NCA</h4>
            <p><strong><a href="https://www.ncanewfs.org">Official Site of the Newfoundland Club of America</a></strong></p>
            <p><em>Visit our <a href="https://members.ncanewfs.org/">Membership Center</a> to become part of the community dedicated to preserving and protecting the Newfoundland Dog</em></p>
          </div>
        </div>
        <div class="col-lg-4 services-section-grid2">
          <h4> Option 2</h4>
          <p>&nbsp;</p>

          <table id="transtable" align="center" width="85%" border="0">
            <tr>
              <td width="50%"><p align="center"><img src="assets/images/healthcoverdb.jpg" width="100%" height="100%" alt="cardiologist listening to a newfoundland puppy heart"></p>
                <p align="center"><strong><a href="#">CHIC<br>Newfoundlands</a></strong></p></td>
              <td width="50%"><p align="center"><img src="assets/images/breedercover.jpg"  width="100%" height="100%" alt="newborn newfoundland puppy"></p>
                <p align="center"><strong><a href="https://www.thenewfoundland.org/rom.php">Register<br>of Merit</a></strong></p></td>
            </tr>
            <tr>
              <td width="50%"><p align="center"><img src="assets/images/activecover.jpg" width="100%" height="100%" alt="newfoundland dog dock diving"></p>
                <p align="center"><strong><a href="https://www.thenewfoundland.org/wa.php">Working Achievement Award</a></strong></p></td>
              <td width="50%"><p align="center"><img src="assets/images/conformationcover.jpg" width="100%" height="100%" alt="newfoundland dog conformation"></p>
                <p align="center"><strong><a href="https://www.thenewfoundland.org/vn.php">Versatile Newfoundlands</a></strong></p></td>
            </tr>
          </table>
        </div>
        <div class="col-lg-4 services-section-grid3">
          <div class="services-section-grid3-top">
            <h4>Need Help?</h4>
            <p><a routerLink="/faqs">Review our FAQ's</a></p>
          </div>
          <div class="services-section-grid3-bottom" style="background: #900909;">
            <h4>Regional Club Tools</h4>
            <p><a routerLink="/admin">Admin Login</a></p>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
<div class="subscribe-section">
  <div class="container">
    <div class="subscribe-section-grids">
      <div class="col-md-8 subscribe">
        <h3>Subscribe to our newsletter</h3>
        <a href="https://lp.constantcontactpages.com/su/jDhV7NM/enotes">Subscribe</a>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<div class="contact-section text-center">
  <div class="container">
    <h3>Contact Us</h3>
    <div class="contact-top">
      <div class="col-md-4 contact-section-grid text-center">
        <i class="smartphone"></i>
        <p>Contact the Regional Club Liaison</p>
        <p><a href="mailto:database@ncadogs.org">regionalclubs&#64;ncanewfs.org</a></p>
      </div>
      <div class="col-md-4 contact-section-grid text-center">
        <i class="user"></i>
        <p> Got A Question? </p>
        <p><a href="mailto:info@ncadogs.org">info&#64;ncadogs.org</a></p>
      </div>
      <div class="col-md-4 contact-section-grid text-center">
        <p>Contact the NCA</p>
        <p><a href="mmailto:correspondence@newfdogclub.org">correspondence&#64;newfdogclub.org</a></p>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="contact-section-bottom">
    </div>
  </div>
</div>
