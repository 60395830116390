import { Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { NavComponent } from './nav/nav.component';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    NavComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit, OnDestroy {
  private auth = inject(AuthService);

  userIsLoggedIn = false;
  isAdmin = false;
  private unsubscribe$ = new Subject<void>();
  // dbAdmins: ACommitteeMember[] = [];
  isAuthenticated$ = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tokenService: TokenStorageService,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.auth.isAuthenticated$.subscribe((isAuth) => {
      this.ngZone.run(() => {
        this.isAuthenticated$ = isAuth
      })
    })
    if (this.isAuthenticated$) {
      this.userIsLoggedIn = true;
      this.isAdmin = true;
    } else {
      this.tokenService.getLoggedInNotification()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.userIsLoggedIn = res;
            this.isAdmin = this.tokenService.isAdminUser();
          });
        });

//    this.router.navigate(['dashboard'], {relativeTo: this.route});
      /*
      this.dogService.getDbAdmins()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          this.ngZone.run(() => {
            if (res.hasOwnProperty('committeeMembers')) {
              this.dbAdmins = res.committeeMembers;
            }
          });
        });
      this.dogService.loadDbAdmins();
       */
    }
  }

  logout() {
    this.tokenService.signOut();
    window.location.reload();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
