import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UploadMetadata } from '../../models/basics.model';
import { ImageService } from '../../services/image.service';
import { Style } from './style';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgClass, NgStyle } from '@angular/common';
import { FileDropDirective } from '../../services/file-drop.directive';
import { MatTooltip } from '@angular/material/tooltip';

export class FileHolder {
  public pending = false;
  public isPdf = false;
  public isTiff = false;
  public pdfSrc: string;
  public filePurpose = '';
  public serverResponse: { status: number, response: any } | undefined;

  constructor(public src: string, public file: File) {
    this.pdfSrc = '';
  }
}
@Component({
  selector: 'app-image-upload',
  standalone: true,
  imports: [
    PdfViewerModule,
    NgStyle,
    NgClass,
    FileDropDirective,
    MatTooltip
  ],
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss'
})
export class ImageUploadComponent  implements OnInit, OnChanges {

  files: FileHolder[] = [];
  fileCounter = 0;
  fileOver = false;
  showFileTooLargeMessage = false;
  uploadedPDF: boolean;

  @Input() buttonCaption = 'Select Images';
  @Input() disabled = false;
  @Input() class = 'img-ul';
  @Input() clearButtonCaption = 'Clear';
  @Input() dropBoxMessage = 'Drop your images here!';
  @Input() fileTooLargeMessage : string | null;
  @Input() headers: any[];
  @Input() max = 100;
  @Input() maxFileSize = 4 * 1024 * 1024;
  @Input() preview = true;
  @Input() partName: string | undefined;
  @Input() style?: Style;
  @Input() extensions: string[] = [];
  @Input() url = '';
  @Input() withCredentials = false;
  @Input() uploadedFiles: string[] | Array<{ url: string, fileName: string, blob?: Blob }> = [];
  @Output() removed = new EventEmitter<FileHolder>();
  @Output() uploadStateChanged = new EventEmitter<boolean>();
  @Output() uploadFinished = new EventEmitter<FileHolder>();
  @Output() previewClicked = new EventEmitter<FileHolder>();
  @Input() registrationNumber?: string;
  @Input() serviceType?: string;
  @Input() filePurpose = 'photo';
  @Input() filebase?: string;

  @Input()
  get issueId(): string { return this._issueId; }
  set issueId(issueId: string) {
    this._issueId = issueId;
    this.deleteAll();
  }
  private _issueId = '';
  @ViewChild('input', {static: true})
  private readonly inputElement: ElementRef | null;
  private pendingFilesCounter = 0;
  @Input() beforeUpload: (param: UploadMetadata) => UploadMetadata | Promise<UploadMetadata> = data => data;

  constructor(private imageService: ImageService,
              private changeDetect: ChangeDetectorRef
  ) {
    this.inputElement = null;
    this.uploadedPDF = false;
    this.fileTooLargeMessage = null;
    this.headers = [];
  }

  ngOnInit() {
    if (this.fileTooLargeMessage === null) {
      this.fileTooLargeMessage = 'An image was too large and was not uploaded.' + (this.maxFileSize ?
        (' The maximum file size is ' + this.maxFileSize / 1024) + 'KiB.' : '');
    }
    // this.extensions = this.extensions ? this.extensions.map((ext) => 'image/' + ext) : ['image/*'];
  }

  deleteAll() {
    this.files.forEach(f => this.removed.emit(f));
    this.files = [];
    this.fileCounter = 0;
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
  }

  deleteFile(file: FileHolder): void {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.fileCounter--;
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
    this.removed.emit(file);
  }

  previewPDFFileClicked() {
    const theFileArray = this.files.slice(0);
    if (theFileArray.length > 0) {
      this.previewClicked.emit(theFileArray[0]);
    }
  }

  previewFileClicked(file: FileHolder) {
    if (file.isTiff) {
      window.open("https://creativecommons.org/licenses/by-sa/4.0", "_blank");
    }
    this.previewClicked.emit(file);
  }

  ngOnChanges(changes: any) {
    if (changes.uploadedFiles && changes.uploadedFiles.currentValue.length > 0) {
      this.processUploadedFiles();
    }
  }

  onFileChange(files: FileList | null) {
    if (files == null || this.disabled) {
      return;
    }

    const remainingSlots = this.countRemainingSlots();
    const filesToUploadNum = files.length > remainingSlots ? remainingSlots : files.length;

    if (this.url && filesToUploadNum !== 0) {
      this.uploadStateChanged.emit(true);
    }

    this.fileCounter += filesToUploadNum;
    this.showFileTooLargeMessage = false;
    this.uploadFiles(files, filesToUploadNum);
  }

  onFileOver = (isOver: boolean ) => this.fileOver = isOver;

  private countRemainingSlots = () => this.max - this.fileCounter;

  private onResponse(response: Response, fileHolder: FileHolder) {
    fileHolder.serverResponse = { status: response.status, response };
    fileHolder.pending = false;

    this.uploadFinished.emit(fileHolder);

    if (--this.pendingFilesCounter === 0) {
      this.uploadStateChanged.emit(false);
    }
  }

  private processUploadedFiles() {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      const data: any = this.uploadedFiles[i];

      let fileIsPdf = false;
      let fileIsTiff = false;
      let pdfSrc = '';
      let fileBlob: Blob,
        file: File,
        fileUrl: string;

      if (data instanceof Object) {
        fileUrl = data.url;
        fileBlob = (data.blob) ? data.blob : new Blob([data]);
        file = new File([fileBlob], data.fileName);
      } else {
        fileUrl = data;
        fileBlob = new Blob([fileUrl]);
        file = new File([fileBlob], fileUrl);
      }

      const newFileH = new FileHolder(fileUrl, file);
      newFileH.filePurpose = this.filePurpose;
      if (fileUrl.toLowerCase().endsWith('.pdf')) {
        newFileH.pdfSrc = fileUrl;
        newFileH.isPdf = true;
      } else {
        if ((fileUrl.toLowerCase().endsWith('.tiff')) ||
          (fileUrl.toLowerCase().endsWith('.tif'))) {
          newFileH.isTiff = true;
        } else {
          newFileH.isTiff = false;
        }
        newFileH.pdfSrc = '';
        newFileH.isPdf = false;
      }
      this.files.push(newFileH);
    }
    this.fileCounter = this.files.length;
  }

  private async uploadFiles(files: FileList, filesToUploadNum: number) {
    for (let i = 0; i < filesToUploadNum; i++) {
      const file = files[i];

      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.fileCounter--;
        if (this.inputElement) {
          this.inputElement.nativeElement.value = '';
        }
        this.showFileTooLargeMessage = true;
        continue;
      }

      const beforeUploadResult: UploadMetadata = await this.beforeUpload({
        file,
        url: this.url,
        abort: false,
        formData: {
          reg: this.registrationNumber,
          service: this.serviceType,
          env: this.issueId,
          filebase: this.filebase
        }});

      if (beforeUploadResult.abort) {
        this.fileCounter--;
        if (this.inputElement) {
          this.inputElement.nativeElement.value = '';
        }
        continue;
      }

      const img = document.createElement('img');
      img.src = window.URL.createObjectURL(beforeUploadResult.file);

      const reader = new FileReader();
      if (beforeUploadResult.file.type !== 'application/pdf')
      {
        reader.addEventListener('load', (event: any) => {
          this.uploadedPDF = false;
          const fileHolder: FileHolder = new FileHolder(event.target.result, beforeUploadResult.file);
          fileHolder.isPdf = false;
          fileHolder.pdfSrc = '';
          if ((beforeUploadResult.file.type === 'image/tiff') ||
            (beforeUploadResult.file.type === 'image/tif')) {
            fileHolder.isTiff = true;
          } else {
            fileHolder.isTiff = false;
          }
          fileHolder.filePurpose = this.filePurpose;
          this.uploadSingleFile(fileHolder, beforeUploadResult.url, beforeUploadResult.formData);
          this.files.push(fileHolder);
//          if (!this.changeDetect['destroyed']) {
          this.changeDetect.detectChanges();
//          }
        }, false);
        reader.readAsDataURL(beforeUploadResult.file);
      } else {
        // file is pdf
        reader.addEventListener('load', (event: any) => {
          this.uploadedPDF = true;
          const fileHolder: FileHolder = new FileHolder(event.target.result, beforeUploadResult.file);
          fileHolder.pdfSrc = event.target.result;
          fileHolder.isPdf = true;
          fileHolder.filePurpose = this.filePurpose;
          this.uploadSingleFile(fileHolder, beforeUploadResult.url, beforeUploadResult.formData);
          this.files.push(fileHolder);
//          if (!this.changeDetect['destroyed']) {
          this.changeDetect.detectChanges();
//          }
        }, false);
        reader.readAsArrayBuffer(beforeUploadResult.file);

      }
    }
  }

  private uploadSingleFile(fileHolder: FileHolder, url = this.url, customForm?: { [name: string]: any }) {
    if (url) {
      this.pendingFilesCounter++;
      fileHolder.pending = true;

      this.imageService
        .postImage(url, fileHolder.file, this.headers, this.partName, customForm, this.withCredentials)
        .subscribe(
          response => this.onResponse(response, fileHolder),
          error => {
            this.onResponse(error, fileHolder);
            this.deleteFile(fileHolder);
          });
    } else {
      this.uploadFinished.emit(fileHolder);
    }
  }
}
