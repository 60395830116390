import { inject, Injectable, NgZone } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { TokenStorageService } from './token-storage.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  private auth = inject(AuthService);
  isAuthenticated$ = false;

  constructor(private tokenService: TokenStorageService,
              private router: Router,
              private ngZone: NgZone) {
    this.auth.isAuthenticated$.subscribe((isAuth) => {
      this.ngZone.run(() => {
        this.isAuthenticated$ = isAuth
      })
    })
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('Url: '+ route.url);
    if (this.isAuthenticated$) {
      return  true;
    }
    if (this.tokenService.isAdminUser()) {
      return true;
    }
    // this.tokenService.setRedirectUrl(url);
    // this.router.navigateByUrl('/login');
    this.router.navigate(["login"],{ queryParams: { retUrl: route.url } });
    return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.tokenService.isAdminUser();
  }
}
