<div class="container">
  <div class="row">
    <div class="col-md-6 mt-5">
      <div class="card card-container p-3">
        <img
          id="profile-img"
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card mt-2"
          alt=""/>
        @if (!isLoggedIn) {
          <h4 class="text-center">NCA Member Login</h4>
          <form
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            novalidate
          >
            <div class="form-group p-4 pb-1">
              <label for="email">Email</label>
              <input
                type="text"
                id="email"
                class="form-control"
                name="email"
                [(ngModel)]="form.email"
                required
                #email="ngModel"
              />
              @if (email.errors && f.submitted) {
                <div
                  class="alert alert-danger"
                  role="alert">
                  Username is required!
                </div>
              }
            </div>
            <div class="form-group p-4">
              <label for="password">Password</label>
              <input
                type="password"
                class="form-control"
                name="password"
                id="password"
                [(ngModel)]="form.password"
                required
                minlength="6"
                #password="ngModel"
              />
              @if (password.errors && f.submitted) {
                <div
                  class="alert alert-danger"
                  role="alert">
                  @if (password.errors['required']) {
                    <div>Password is required</div>
                  }
                  @if (password.errors['minlength']) {
                    <div>Password must be at least 6 characters</div>
                  }
                </div>
              }
            </div>
            <div class="form-group text-center mb-2">
              <button class="btn btn-primary btn-block">
                Login
              </button>
            </div>
            <div class="form-group">
              @if (f.submitted && isLoginFailed) {
                <div
                  class="alert alert-danger"
                  role="alert">
                  Login failed: {{ errorMessage }}
                </div>
              }
            </div>
          </form>
        }
      </div>
    </div>
    <div class="col-md-6 mt-5">
      <div class="card card-container text-center p-3">
        <img
          id="profile2-img"
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card mt-2"
          alt=""/>
        <h4 class="mt-3">Not an NCA Member?</h4>
        <p>Have you already signed up for an account?</p>
        <p>Use the Login button below to login to the portal</p>
        <app-auth-button></app-auth-button>
        <p>If you don't have an account, please contact the Committee liaison for your Regional Club to request access</p>
      </div>
    </div>
  </div>
</div>

