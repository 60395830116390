import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdminComponent } from './admin/admin.component';
import { DashComponent } from './admin/dash/dash.component';
import { LoginComponent } from './login/login.component';
import { ClubsComponent } from './admin/clubs/clubs.component';
import { UsersComponent } from './admin/users/users.component';
import { WorkingDogOnlineEntryComponent } from './admin/working-dog-online-entry/working-dog-online-entry.component';

export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '', component: DashComponent
      },
      {
        path: 'dashboard',
        component: DashComponent
      },
      {
        path: 'clubs',
        component: ClubsComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'wdoe',
        component: WorkingDogOnlineEntryComponent
      }
    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '**', component: HomeComponent }
];
