import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthButtonComponent } from '../../auth-button/auth-button.component';
import { TokenStorageService } from '../../services/token-storage.service';
import { Subject, takeUntil } from 'rxjs';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { RegionalClub } from '../../models/basics.model';
import { RegionalclubService } from '../../services/regionalclub.service';
import { AddNewUserModalComponent } from '../add-new-user-modal/add-new-user-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    AuthButtonComponent,
    MatSlideToggle,
    FormsModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, OnDestroy {
  allClubs: RegionalClub[] = [];
  workingClubs: RegionalClub[] = [];
  private unsubscribe$ = new Subject<void>();
  isSuperAdmin = false;
  isLiaison = false;
  danzEnabled = true;
  suemEnabled = true;
  mlzEnabled = true;
  saEnabled = true;
  jnEnabled = true;
  enEnabled = true;
  protected regionalClubName = 'Unknown Club Name';
  protected regionalClubAbbr = 'Unknown Club Abbr';
  myClub: RegionalClub = {
    abbreviation: '',
    areaServed: '',
    contactEmail: '',
    contactName: '',
    facebookId: '',
    facebookUrl: '',
    id: 0,
    instagramUrl: '',
    isActive: 0,
    logoFileName: '',
    name: '',
    rescueUrl: '',
    twitterUrl: '',
    websiteUrl: '',
    zipCodes: ''
  }

  constructor(private tokenService: TokenStorageService,
              private regService: RegionalclubService,
              private modalService: NgbModal,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    const roles = this.tokenService.getRoles();
    if (roles && roles.length > 0) {
      if (roles[0].role === 'superAdmin') {
        this.isSuperAdmin = true;
      } else {
        this.regionalClubAbbr = roles[0].abbr;
        this.regionalClubName = roles[0].name;
        this.loadMyClubInfo();
      }
    }
    this.regService.getRegionalClubs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.ngZone.run(() => {
          if (res.hasOwnProperty('regionalClubs')) {
            this.allClubs = res.regionalClubs;
            if (this.isSuperAdmin) {
              this.workingClubs = this.allClubs;
            } else {
              this.loadMyClubInfo();
            }
          }
        });
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addSuperAdmin() {
    AddNewUserModalComponent.open(this.modalService);
  }

  loadMyClubInfo() {
    const foundMyClub = this.allClubs.filter((aClub) => {
      return aClub.name == this.regionalClubName
    });
    if (foundMyClub.length > 0) {
      this.myClub = foundMyClub[0];
      this.workingClubs = [this.myClub];
    }
  }
  addClubAdmin() {
    AddNewUserModalComponent.open(this.modalService);
  }
}
