import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  hideFooter = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((event2) =>
    {
      if (event2 instanceof NavigationEnd) {
        this.hideFooter = event2.url.startsWith('/search') || event2.url.startsWith('/admin');
      }
    });
  }

}
